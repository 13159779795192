<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <header>
          <h3>本日ご利用可能なクーポン</h3>
        </header>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6"
        v-for="coupon in coupons"
        :key="coupon.coupon_id"
      >
        <v-card>
          <figure
            class="rounded-t"
          >
            <v-img
              max-height="300"
              contain
              :src="coupon.image_url"
              :title="coupon.title"
            >
            </v-img>
          </figure>

          <v-card-title>
            <h3>{{ coupon.title }}</h3>
          </v-card-title>

          <v-card-subtitle class="py-2">
            <p class="font-weight-bold">『{{ coupon.description }}』</p>
          </v-card-subtitle>

          <v-card-text class="pb-0">
            <p>
              割引き額：{{ coupon.discount }}円
            </p>
            <p>
              有効期間：<span>{{ formatDate(coupon.start_date) }}</span> 〜 <span>{{ formatDate(coupon.end_date) }}</span>
            </p>
            <p>
              利用履歴：{{ couponCount(coupon) }}回／{{ couponUseMax(coupon.max_use_count) }}
            </p>
            <p>
              前回利用：{{ couponLastUsed(coupon.used_at) }}
            </p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-model="coupon.btn"
              depressed small absolute right bottom
              color="primary"
              :disabled="coupon.max_use_count !== 0 && coupon.use_count >= coupon.max_use_count"
              @click="useCouponClicked(coupon)"
            >
              使用する
            </v-btn>
          </v-card-actions>

          <!-- 非表示オーバーレイ -->
          <v-fade-transition>
            <v-overlay
              v-if="coupon.max_use_count !== 0 && coupon.use_count >= coupon.max_use_count"
              absolute
              z-index="2"
              color="accent"
            >
              <v-btn
                color="accent"
              >使用上限に達しました</v-btn>
            </v-overlay>
          </v-fade-transition>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<script>
import moment from 'moment'
import { API_ENDPOINT } from '@/literals.js'
import { CiApiTool } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'

export default {
  components: {
    'loader': Loader,
    'modal-confirm': ModalConfirm,
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },
    aToken: {
      type: String,
      required: true,
    }
  },

  //***************************************************
  //データ
  //***************************************************
  data() {
    return {
      coupons: [],
      displayName: 'お客',
      shopName: null,
      modalMessage: '',
      loading: false,
      loadingMessage: null,
      apiTool: new CiApiTool(API_ENDPOINT, this.shopId, this.aToken),
    }
  },

  //***************************************************
  //算出
  //***************************************************
  computed: {
    formatDate() {
      return date => {
        if (moment(date).format('YYYY') === moment(new Date()).format('YYYY')) {
          if (moment(date).format('HH:mm') === '00:00') {
            return moment(date).format('M月D日')
          } else {
            return moment(date).format('M月D日 HH:mm')
          }
        } else {
          if (moment(date).format('HH:mm') === '00:00') {
            return moment(date).format('YYYY年M月D日')
          } else {
            return moment(date).format('YYYY年M月D日 HH:mm')
          }
        }
      }
    },
    couponCount() {
      return coupon => {
        if (coupon.use_count) {
          return coupon.use_count === -1 ? coupon.max_use_count : coupon.use_count
        } else {
          return 0
        }
      }
    },
    couponUseMax() {
      return maxCount => (maxCount <= 0 ? '無制限' : maxCount + '回まで')
    },
    couponLastUsed() {
      return usedAt => usedAt ? this.formatDate(usedAt) : '未使用'
    },
  },

  //***************************************************
  //ライフサイクル
  //***************************************************
  created() {
    this.getData()
    .catch(() => alert('データ取得中にエラーが発生しました\nしばらくしてからもう一度試して下さい') )
  },

  //***************************************************
  //メソッド
  //***************************************************
  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = 'クーポンデータ取得中・・・'

      await this.apiTool.getReqCi('coupons/').then( results => {
        if (!results || !results.length) return

        results.map( coupon => {
          coupon.use_count = 0
          coupon.used_at = ''
          this.coupons.push(coupon)
        })
      })

      await this.apiTool.getReqCi('coupon-use-history/').then( results => {
        if (!results || !results.length) return

        results.map( history => {
          const target = this.coupons.find( coupon => coupon.coupon_id === history.coupon_id )
          if (target !== undefined) {
            target.use_count = history.use_count
            target.used_at = history.used_at
          }
        })
      })
      this.loading = false
    },

    //使用ボタンクリック
    useCouponClicked(coupon) {
      this.modalMessage = '<p>クーポンは料金お支払いの際に店舗スタッフ確認の下ご利用下さい。</p>'

      const modalHanddown = {
        yesCallback: this.countUpCoupon,
        comeBack: coupon,
        buttonLabel: '使用する',
      }
      this.$refs.modalConfirm.open(modalHanddown)
    },

    //**************************************************
    //クーポン利用履歴作成
    //**************************************************
    countUpCoupon(coupon) {
      const apiPartial = 'create/coupon-use-history/' + coupon.coupon_id

      this.apiTool.apiReqCiWithData('POST', apiPartial).then(() => {
        coupon.use_count++
        coupon.used_at = moment(new Date()).format()
      })
      .catch(() => alert('更新中にエラーが発生しました\nしばらくしてからもう一度試して下さい') )
      .then(() => this.$refs.modalConfirm.close() )
    }
  }
}
</script>

<style scoped>
p {
  margin-bottom: 0;
}
</style>
